<template>
	<div>
		<div class="header-box">
			<img class="left" src="../assets/wx_logo.png"/>
			<div class="right">咨询热线：023-6868-9386</div>
		</div>
		
		<el-carousel height="500px" arrow='never' indicator-position='none'>
			<el-carousel-item v-for="item in 1" :key="item">
				<div class="banner">
					<div class="banner-left">
						<div class="title">创序网校学会计</div>
						<div class="title">入名企 拿高薪</div>
						<div class="msg">选择创序网校，就是选择98.35%通过率！</div>
						<img src="../assets/downAPP.png" />
						<div class="xz">iphone、android下载</div>
					</div>
					<div class="banner-right">
						<img class="banner-right-img" src="../assets/banner_right.png" />
					</div>
				</div>

			</el-carousel-item>
		</el-carousel>
		<div class="testing-box">
			<div class="testing-top">
				<div class="title">一年拿下高级会计职称开启升职加薪快速通道</div>
				<div class="msg">— ONE YEAR SENIOR ACCOUNTING TITLE —</div>
				<!-- <el-button type="primary" class="check-testing">立即查看</el-button> -->
			</div>
			<div class="testing-msg-box">
				<div class="box">
					<img class="img1" src="../assets/home01.png" />
					<div class="msg">据国家政策要求，高级18万人持证人数即将饱和</div>
				</div>
				<div class="box">
					<img class="img2" src="../assets/home02.png" />
					<div class="msg">近年来，越来越多的城市均出台相关政策</div>
				</div>
				<div class="box">
					<img class="img3" src="../assets/home03.png" />
					<div class="msg">越来越多的企事业单位将会计师列为重要职位</div>
				</div>
				<div class="box">
					<img class="img4" src="../assets/home04.png" />
					<div class="msg">拿下高级会计职称及早在财会行业占据一席之地</div>
				</div>
			</div>
			<div class="testing-img-box">
				<img src="../assets/home1.png" />
			</div>
		</div>
		<div class="content-box">
			<img class="content-box-left" src="../assets/left_img.png" />
			<div class="content-box-right">
				<div class="title">2021年会计职称政策变化预测解析</div>
				<div class="content">
					财政部发布《会计专业技术人员继续教育规定（征求意见稿）》节选：
					①对会计人员管理的新形势，需加强会计专业技术人员继续教育工作。
					②会计从业资格取消后，会计人员管理工作面临转型，加强会计专业技术人员继续教育是做好新时期会计人员管理工作的一项重要内容，也是从制度上引导、督促会计专业技术人员履行继续教育义务、提高业务素质和专业胜任能力、最终提高会计信息质量的有效途径。
				</div>
			</div>
		</div>
		<div class="content-box1">
			<div class="title">
				<div style='color: #F06B40;margin-right: 10px;'>有证有能力</div>
				<div style='color: #515151;margin-left: 10px;'>才有竞争力</div>
			</div>
			<div class="msg">— Only with certificate and ability can we be competitive —</div>
			<img src="../assets/home2.png" />
		</div>
		<div class="content-box2">
			<div class="title">
				<div style='color: #F06B40;margin-right: 10px;'>技能提升</div>
				<div style='color: #D2D2D2;margin-left: 10px;'>业余充电的不二选择</div>
			</div>
			<div class="msg">— The best choice for amateur charging —</div>
			<div class="jn-box">
				<div class="box1">
					<img class="img1" src="../assets/home001.png"/>
					<div class="box1-title">零基础就业岗位</div>
					<div class="line"></div>
					<div class="content">会计理论知识+实操做账+真账实习一步到位，对就业无经验说"NO"，零基础上岗so easy!</div>
				</div>
				<div class="box1">
					<img class="img2" src="../assets/home002.png"/>
					<div class="box1-title">真账实操技能提升</div>
					<div class="line"></div>
					<div class="content">小规模纳税人、一般纳税人、手工账、电脑账、多行业实操经验积累，税务实务，财务管理等</div>
				</div>
				<div class="box1">
					<img class="img1" src="../assets/home003.png"/>
					<div class="box1-title">财税管控、企业内训</div>
					<div class="line"></div>
					<div class="content">真实演练国税、地税报税流程，报表分析，税收筹划，风险控制与管理。</div>
				</div>
			</div>
		</div>
		<div class="content-box3">
			<div class="content-box3-left">
				<div class="title1">摸索是最大的成本，犹豫是最大的阻碍！</div>
				<div class="line"></div>
				<div class="title2">课程适不适合我？试听就知道！</div>
				<div class="title2">课程效果好不好？100000学员共同见证！</div>
				<div class="title2">课程有没有保障？我们可以承诺：0风险！</div>
				<div class="title2">选择创序，您不只是一次学习，而是一次改变！这一刻，我们与您相遇！</div>
			</div>
			<div class="content-box3-right">
				<img src="../assets/downAPP.png"/>
				<div class="xz-font">扫码下载创序网校吧</div>
			</div>
		</div>
		<div class="footer">
			创序网校 : 渝ICP备18002384号-1
		</div>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {
				sublist: []
			}
		},
		methods: {
			
		}
	}
</script>

<style>
	.el-carousel__item:nth-child(2n+1){
		background-color: #FFFFFF !important;
	}
	
	.el-main {
		padding: 0 !important;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.header-box{
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		padding: 30px 150px;
		border-bottom: 1px solid #f0f0f0;
	}
	.header-box .left{
		width: 143px;
		height: 44px;
		opacity: 1;
	}
	.header-box .right{
		opacity: 1;
		font-size: 18px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: center;
		color: #515151;
		line-height: 25px;
	}
	
	.class-types-box {
		background-color: #FFFFFF;
		margin: 0 auto;
		width: 1440px;
	}

	.class-types {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 30px 150px;
	}

	.class-types div {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(81, 81, 81, 1);
		line-height: 22px;
		width: 129px;
		height: 24px;
		background: rgba(250, 250, 250, 1);
		border-radius: 10px;
		border: 1px solid rgba(240, 240, 240, 1);
		padding: 13px 22px;
		margin-right: 2px;
		margin-bottom: 20px;
		cursor: pointer;
	}


	.question-bank-box {
		height: 634px;
		background-color: #F0F0F0;
		padding: 62px 150px 78px 150px;
	}

	.question-bank-box .question-top {
		text-align: center;
	}

	.question-bank-box .title {
		font-size: 34px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(81, 81, 81, 1);
		line-height: 48px;
	}

	.question-bank-box .msg {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(181, 181, 181, 1);
		line-height: 20px;
		width: 825px;
		margin: 0 auto;
		margin-top: 6px;
	}

	.question-bank-box .check-question {
		background: rgba(40, 178, 254, 1);
		border-radius: 4px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(255, 255, 255, 1);
		width: 160px;
		height: 36px;
		margin-top: 20px;
		border-color: none;
		padding: 0;
	}

	.question-bank-box .question-bottom {
		display: flex;
		justify-content: center;
		margin-top: 23px;
	}

	.question-bank-box .question-bottom img {
		width: 362px;
		height: 500px;
		padding: 10px;
	}


	.testing-box {
		padding: 64px 150px 78px 150px;
		background: linear-gradient(180deg,#ffffff, #fafafa);
	}

	.testing-box .testing-top {
		text-align: center;
	}

	.testing-box .title {
		font-size: 34px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 48px;
	}

	.testing-box .msg {
		font-size: 24px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #d2d2d2;
		line-height: 20px;
		width: 825px;
		margin: 0 auto;
		margin-top: 6px;
	}

	.testing-box .check-testing {
		background: rgba(40, 178, 254, 1);
		border-radius: 4px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(255, 255, 255, 1);
		width: 160px;
		height: 36px;
		margin-top: 20px;
		border-color: none;
		padding: 0;
	}

	.testing-img-box {
		margin-top: 36px;
		display: flex;
		justify-content: center;
	}

	.testing-img-box img {
		width: 1140px;
		height: 521px;
	}

	.video-course-box {
		height: 482px;
		padding: 64px 150px 78px 150px;
		background-color: #F0F0F0;
	}

	.video-course-box .video-course-top {
		text-align: center;
	}

	.video-course-box .title {
		font-size: 34px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(81, 81, 81, 1);
		line-height: 48px;
	}

	.video-course-box .msg {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(181, 181, 181, 1);
		line-height: 20px;
		width: 825px;
		margin: 0 auto;
		margin-top: 6px;
	}

	.video-course-box .check-video-course {
		background: rgba(40, 178, 254, 1);
		border-radius: 4px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(255, 255, 255, 1);
		width: 160px;
		height: 36px;
		margin-top: 20px;
		border-color: none;
		padding: 0;
	}

	.video-course-img-box {
		display: flex;
		justify-content: center;
		margin-top: 34px;
	}

	.video-course-box1 {
		width: 550px;
		height: 267px;
		background: rgba(255, 255, 255, 1);
		border: 1px solid rgba(230, 230, 230, 1);
		text-align: center;
		padding-top: 48px;
		margin-left: 40px;
	}

	.medical-atlas-box {
		height: 377px;
		padding: 54px 150px 78px 150px;
	}

	.medical-atlas-box .medical-atlas-top {
		text-align: center;
	}

	.medical-atlas-top .title {
		font-size: 34px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(81, 81, 81, 1);
		line-height: 48px;
	}

	.medical-atlas-top .msg {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(181, 181, 181, 1);
		line-height: 20px;
		width: 825px;
		margin: 0 auto;
		margin-top: 6px;
	}

	.medical-atlas-bottom {
		width: 1140px;
		padding: 0 150px;
		display: flex;
		flex-wrap: wrap;
		margin: 0 auto;
		justify-content: space-between;
	}

	.medical-box {
		width: 500px;
		height: 110px;
		background: rgba(252, 252, 252, 1);
		border: 1px solid rgba(240, 240, 240, 1);
		display: flex;
		padding: 15px 43px 15px 17px;
		margin-top: 20px;
	}

	.medical-box-right {
		margin-left: 25px;
		margin-top: 4px;
	}

	.medical-box-right .title {
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: rgba(81, 81, 81, 1);
		line-height: 28px;
	}

	.medical-box-right .msg {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 200;
		color: rgba(81, 81, 81, 1);
		line-height: 22px;
	}

	.teachers-box {
		height: 811px;
		padding: 64px 150px 82px 150px;
		background-color: #F0F0F0;
	}

	.teachers-box .teachers-top {
		text-align: center;
	}

	.teachers-top .title {
		font-size: 34px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(81, 81, 81, 1);
		line-height: 48px;
	}

	.teachers-top .msg {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(181, 181, 181, 1);
		line-height: 20px;
		width: 825px;
		margin: 0 auto;
		margin-top: 6px;
	}

	.teachers-team {
		width: 1440px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	.teachers-team .box {
		padding: 20px;
	}

	.teacher-box {
		width: 256px;
		height: 284px;
		background: rgba(255, 255, 255, 1);
		padding: 23px 0px;
		text-align: center;
	}

	.teachers-team img {
		width: 191px;
		height: 191px;
		border-radius: 50%;
	}

	.teachers-team .name {
		margin-top: 31px;
		font-size: 18px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: rgba(81, 81, 81, 1);
		line-height: 25px;
	}

	.teachers-team .info {
		margin-top: 6px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(81, 81, 81, 1);
		line-height: 20px;
	}

	.about-box {
		height: 266px;
		padding: 102px 150px;
		display: flex;
		justify-content: center;
	}

	.about-left {
		text-align: center;
		width: 491px;
		height: 244px;
		background: rgba(250, 250, 250, 1);
		border: 1px solid rgba(240, 240, 240, 1);
		padding-top: 22px;
	}

	.about-right {
		margin-left: 65px;
		margin-top: 67px;
	}

	.about-title {
		font-size: 34px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(81, 81, 81, 1);
		line-height: 48px;
	}

	.about-info {
		margin-top: 10px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(181, 181, 181, 1);
		line-height: 25px;
		width: 580px;
	}

	.banner {
		width: 1222px;
		display: flex;
		margin: 0 auto;
		padding: 102px 68px 0px 150px;
		justify-content: space-between;
	}

	.banner .title {
		font-size: 40px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: rgba(51, 51, 51, 1);
		line-height: 56px;
	}

	.banner .msg {
		margin-top: 6px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(51, 51, 51, 1);
		line-height: 25px;
	}

	.banner .downapp {
		background: rgba(40, 178, 254, 1);
		border-radius: 4px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(255, 255, 255, 1);
		width: 160px;
		height: 36px;
		margin-top: 20px;
		border-color: none;
		padding: 0;
		display: block;
	}

	.banner-left img {
		width: 150px;
		height: 150px;
		margin-top: 5px;
	}
	.xz{
		width: 150px;
		height: 30px;
		opacity: 1;
		background: #f8f8f8;
		border-radius: 6px;
		text-align: center;
		line-height: 30px;
		color: #515151;
		font-size: 14px;
	}
	.banner-right-img{
		width: 324px;
		height: 444px;
	}
	
	.content-box{
		display: flex;
		width: 1140px;
		margin: 0 auto;
		margin-top: 78px;
		padding-bottom: 60px;
	}
	.content-box-left{
		width: 580px;
		height: 424px;
	}
	.content-box-right{
		width: 560px;
	}
	.content-box-right .title{
		margin-top: 60px;
		opacity: 1;
		font-size: 34px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #515151;
		line-height: 48px;
	}
	.content-box-right .content{
		opacity: 1;
		font-size: 18px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: justify;
		color: #515151;
		line-height: 36px;
	}
	.testing-msg-box{
		display: flex;
		justify-content: space-between;
		width: 1440px;
		margin: 0 auto;
		margin-top: 67px;
	}
	.testing-msg-box .img1{
		width: 41px;
		height: 50px;
	}
	.testing-msg-box .img2{
		width: 50px;
		height: 50px;
	}
	.testing-msg-box .img3{
		width: 39px;
		height: 50px;
	}
	.testing-msg-box .img4{
		width: 50px;
		height: 44px;
		margin-top: 6px;
	}
	.testing-msg-box .box{
		width: 220px;
		text-align: center;
	}
	.testing-msg-box .msg{
		opacity: 1;
		font-size: 18px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: center;
		color: #515151;
		line-height: 25px;
		width: 220px !important;
	}
	.content-box1{
		padding: 85px 150px 0px 150px;
		text-align: center;
		background: linear-gradient(180deg,#ffffff, #fafafa 100%);

	}
	.content-box1 .title{
		opacity: 1;
		font-size: 60px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #f06b40;
		line-height: 50px;
		display: flex;
		justify-content: center;
	}
	.content-box1 .msg{
		margin-top: 27px;
		opacity: 1;
		font-size: 24px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #d2d2d2;
		line-height: 33px;
	}
	.content-box1 img{
		width: 1140px;
		height: 537px;
		margin-top: 30px;
	}
	
	.content-box2{
		padding: 85px 150px 100px 150px;
		text-align: center;
		background: linear-gradient(180deg,#ffffff, #fafafa 100%);
	
	}
	.content-box2 .title{
		opacity: 1;
		font-size: 60px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #f06b40;
		line-height: 50px;
		display: flex;
		justify-content: center;
	}
	.content-box2 .msg{
		margin-top: 27px;
		opacity: 1;
		font-size: 24px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #d2d2d2;
		line-height: 33px;
	}
	.jn-box{
		width: 1000px;
		margin: 0 auto;
		margin-top: 85px;
		display: flex;
		justify-content: space-between;
	}
	.jn-box .box1{
		width: 234px;
		height: 333px;
		background-color: #F8F8F8;
		text-align: center;
		padding:47px 30px 0 30px;
	}
	.jn-box .img1{
		width: 44px;
		height: 44px;
	}
	.jn-box .img2{
		width: 44px;
		height: 39px;
	}
	.box1 .box1-title{
		margin-top: 28px;
		opacity: 1;
		font-size: 26px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #515151;
		line-height: 37px;
	}
	.box1 .line{
		width: 160px;
		height: 2px;
		background: #515151;
		margin:0 auto;
		margin-top: 28px;
	}
	.box1 .content{
		margin-top: 30px;
		font-size: 20px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: center;
		color: #515151;
		line-height: 28px;
	}
	.content-box3{
		padding: 73px 150px;
		display: flex;
		justify-content: center;
		background: linear-gradient(180deg,#ffffff, #fafafa 100%);
	}
	.content-box3 .title1{
		font-size: 38px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #515151;
		line-height: 53px;
		margin-bottom: 30px;
		margin-top: 30px;
	}
	.content-box3 .line{
		width: 773px;
		height: 1px;
		background: #515151;
		margin-bottom: 26px;
	}
	.content-box3 .title2{
		font-size: 24px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: left;
		color: #515151;
		line-height: 50px;
	}
	.content-box3-right{
		margin-left: 73px;
		width: 220px;
		height: 322px;
		opacity: 1;
		background: #f8f8f8;
		padding: 32px 37px 0 37px;
	}
	.content-box3-right img{
		width: 220px;
		height: 220px;
	}
	.xz-font{
		margin-top:36px;
		font-size: 24px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #515151;
		line-height: 33px;
	}
	.footer{
		width: 100%;
		height: 150px;
		opacity: 1;
		font-size: 18px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: center;
		color: #515151;
		line-height: 150px;
		
	}
</style>
